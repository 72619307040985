import { render, staticRenderFns } from "./partnerListDetailList.vue?vue&type=template&id=58567534&scoped=true&"
import script from "./partnerListDetailList.vue?vue&type=script&lang=js&"
export * from "./partnerListDetailList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58567534",
  null
  
)

export default component.exports